// NOTE If it is an option to be used in the operating environment, add it to webpack.DefinePlugin.
import { InHouseGame } from "src/core/casino/casino.model";

const environment = {
    development: {
        isProduction: false,
        assetsPath: `http://${process.env.HOST || "localhost"}:${process.env.PORT || 3000}/dist/`,
    },
    production: {
        isProduction: true,
        assetsPath: "/dist/",
    },
}[process.env.NODE_ENV || "development"];

const title = "Peergame";
const description = "Bitcoin SV game platform - No Registration · Instant Payouts · Provable Fairness - In-house games Powered by BSV blockchain.";
const ogImage = process.env.OG_IMAGE;

const config = {
    host: process.env.HOST || "localhost",
    port: process.env.PORT,
    apiHost: process.env.APIHOST || "http://localhost",
    app: {
        head: {
            title,
            meta: [
                {
                    name: "description",
                    content: description,
                },
                {
                    name: "keywords",
                    content: "Bitcoin SV, blockchain, game",
                },
                {
                    name: "twitter:card",
                    content: `${process.env.INDEX}${ogImage}`,
                },
                {
                    name: "twitter:site",
                    content: "@PeergameEng",
                },
                {
                    name: "twitter:title",
                    content: "Peergame",
                },
                {
                    name: "twitter:description",
                    content: description,
                },

                {
                    property: "og:title",
                    content: title,
                },
                {
                    property: "og:site_name",
                    content: title,
                },
                {
                    property: "og:image",
                    content: `${process.env.INDEX}${ogImage}`,
                },
                {
                    property: "og:url",
                    content: process.env.INDEX,
                },
                {
                    property: "og:description",
                    content: description,
                },

                {
                    name: "viewport",
                    content:
                        "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no",
                },

                {
                    name: "mobile-web-app-capable",
                    content: "yes",
                },
                {
                    name: "apple-mobile-web-app-capable",
                    content: "yes",
                },
                {
                    name: "application-name",
                    content: title,
                },
                {
                    name: "apple-mobile-web-app-status-bar-style",
                    content: "black",
                },
                {
                    name: "apple-mobile-web-app-title",
                    content: title,
                },
                {
                    name: "theme-color",
                    content: "#12033d",
                },
            ],
            link: [
                {
                    href: "/assets/css/swiper.min.css",
                    rel: "stylesheet",
                },
                {
                    href: "/assets/css/react-toastify.min.css",
                    rel: "stylesheet",
                },
                {
                    href: "/manifest.json",
                    rel: "manifest",
                },
                {
                    href: process.env.APIHOST,
                    rel: "preconnect",
                },
                {
                    href: "https://yt3.ggpht.com",
                    rel: "preconnect",
                },
                {
                    href: "https://cdn.jsdelivr.net",
                    rel: "preconnect",
                },
            ],
            script: [
                {
                    src: "https://widget.changelly.com/affiliate.js",
                    async: true,
                },
                {
                    src:
                        "//d.bablic.com/snippet/5e577416279d870001cda277.js?version=3.9",
                    async: true,
                },
                {
                    src:
                        "https://popups.landingi.com/api/v2/website/install-code?apikey=1a2af414-08cb-42ab-846b-3f7775c14bb6",
                    async: true,
                },
            ],
        },
    },
    landing: "/",
    lottery: "/bitto",
    turtleRace: "/turtlerace",
    ladderGame: "/laddergame",
    baccarat: "/baccarat",
    roulette: "/roulette",
    dice: "/dice",
    gtmAcc: process.env.GTM_ACC,
    index: process.env.INDEX,
    pusherAppKey: process.env.PUSHER_APP_KEY,
    asset: process.env.ASSET,
    ogImage: process.env.OG_IMAGE,
    explorerTx: process.env.EXPLORER_TX,
    explorerBlock: process.env.EXPLORER_BLOCK,
    chatEndpoint: process.env.CHAT_ENDPOINT!,
    authHost: process.env.AUTH_HOST,
    moneyButtonClientIdentifier: process.env.MONEYBUTTON_CLIENT_IDENTIFIER,
    noMoreBetsDuration: {
        [InHouseGame.Turtle]: 10,
        [InHouseGame.Ladder]: 10,
    },
    curacaoLicenseId: "5ed251dd-7abb-4e27-a88f-6648fb56bbaa",
    enableWalletConnect: process.env.ENABLE_WALLET_CONNECT === "true",
    enableYola: process.env.ENABLE_YOLA === "true",
    yolaId: process.env.YOLA_ID,
    hotjarSiteId: process.env.HOTJAR_SITE_ID || 0,
};

Object.assign(config, environment);

export default config;
