import { useEffect, useState } from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Layout from "src/affiliate/components/Layout";
import { FlexContainer, FlexItem } from "src/affiliate/styled/layout.styled";
import { Caption } from "src/affiliate/styled/typography.styled";
import Button from "src/affiliate/components/reusable/Button";
import CampaignsBlog from "src/affiliate/components/campaigns/CampaignBlog";
import DashBoardLayout from "src/affiliate/components/reusable/DashBoardLayout";
import useModal from "src/affiliate/hooks/use-modal";
import BaseModal from "src/components/base-modal/BaseModal";
import Input from "src/affiliate/components/reusable/Input";
import CampaignDeleteModalContent from "src/affiliate/components/campaigns/CampaignDeleteModalContent";
import { campaignSchema } from "src/affiliate/components/campaigns/config";
import usePagination from "src/affiliate/hooks/use-pagination";
import Pagination from "src/affiliate/components/reusable/Pagination";
import { axiosInstance } from "src/core/http/axios-instance";
import CreateCampaignModal from "src/affiliate/components/reusable/CreateCampaignModal";
import useCampaign from "src/affiliate/hooks/use-campaign";
import toastNotify from "src/affiliate/utils/toastNotify";
import Loader from "src/components/loader/Loader";

interface ICampaignsFormValues {
    name?: string;
    code?: string;
}

const StyledForm = styled.form`
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 32px;
    flex-wrap: wrap;
`;

const Campaigns = () => {
    const [totalPage, setTotalPage] = useState(0);
    const [editInputValues, setEditInputValues] = useState<ICampaignsFormValues>({
        name: "",
        code: "",
    });

    const [open, onOpen, onClose] = useModal();
    const [deleteCampaignOpenModal, deleteCampaignOnOpen, deleteCampaignOnClose] = useModal();
    const [editCampaignOpenModal, editCampaignOnOpen, editCampaignOnClose] = useModal();
    const [id, setId] = useState<ICampaignsFormValues["code"]>("");
    const {
        pagination, prevPage, nextPage, changePage, currentPage,
    } = usePagination(0, totalPage);
    const {
        campaignList, totalPages, loadedCampaigns, getCampaigns,
    } = useCampaign(`/api/v1/affiliate/campaign?size=3&page=${currentPage}`);

    useEffect(() => {
        setTotalPage(() => totalPages);
    }, [campaignList, totalPages]);

    const {
        register,
        handleSubmit,
        formState: { isValid, errors },
        reset,
    } = useForm<ICampaignsFormValues>({
        defaultValues: {
            code: "",
            name: "",
        },
        resolver: yupResolver(campaignSchema),
        mode: "all",
    });

    const {
        register: editRegister,
        handleSubmit: editHandleSubmit,
        formState: editFormState,
        reset: editReset,
    } = useForm({
        resolver: yupResolver(campaignSchema),
        mode: "onChange",
    });

    const onDeleteModalOpen = (campaignCode: ICampaignsFormValues["code"]) => {
        deleteCampaignOnOpen();
        setId(campaignCode);
    };

    const onEditModalOpen = (values: ICampaignsFormValues) => {
        editCampaignOnOpen();
        setEditInputValues(values);
    };
    const onEditSubmit = (value: ICampaignsFormValues) => {
        axiosInstance
            .put(`/api/v1/affiliate/campaign/${editInputValues.code}`, value)
            .then(() => getCampaigns())
            .catch(e => {
                if (e) {
                    toastNotify("Conflict", 3000, true);
                }
            });

        editReset();
        editCampaignOnClose();
    };
    const onDelete = (id?: string) => {
        if (!id) return

        axiosInstance
            .post(`api/v1/affiliate/campaign/inactive/${id}`)
            .then(() => getCampaigns())
            .catch(e => console.error(e));
        deleteCampaignOnClose();
    };
    const onCampaignCreated = () => {
        getCampaigns().then(newTotalPages => {
            if (newTotalPages && currentPage !== newTotalPages - 1) {
                changePage(newTotalPages - 1);
            }
        });
    };
    return (
        <>
            <Helmet>
                <title>Campaigns</title>
                <meta property="og:title" content="Peergame Partners - Partner up with the highest-paid commission casino affiliate program." />
                <meta
                    name="description"
                    content="The original Bitcoin SV Dice Game. Provably fair with the lowest house edge, instant payout, and enhanced privacy"
                />
            </Helmet>
            <Layout transparent sideNav>
                {loadedCampaigns ? (
                    <DashBoardLayout title="Campaigns" mobilePadding="22px 0 50px 0">
                        <FlexContainer margin="19px 0 35px 0" mobilePadding="0 16px 0 16px" justifyContent="space-between" mobileFlexDirection="column">
                            <Caption mobileMargin="0 0 16px 0" width="412px" mobileWidth="328px">
                                You will forever receive commission from every user you refer. Every bet they make you will receive a percentage.
                            </Caption>
                            <Button onClick={onOpen} width="260px" buttonShape="bordered">
                                Create New Campaign
                            </Button>
                        </FlexContainer>
                        <FlexContainer flexDirection="column" height="716px" tabletHeight="954px" mobileHeight="auto">
                            {campaignList.map(({
                                name, created_at: createdAt, visits, referrals, wagered, commission, link, code,
                            }) => (
                                <CampaignsBlog
                                    key={name}
                                    name={name}
                                    createdAt={createdAt}
                                    visits={visits}
                                    referrals={referrals}
                                    wagered={wagered}
                                    commission={commission}
                                    link={link}
                                    deleteOnOpen={() => onDeleteModalOpen(code)}
                                    editOnOpen={() => onEditModalOpen({ name, code })}
                                />
                            ))}
                        </FlexContainer>
                        {totalPages > 1 ? <Pagination pagination={pagination} nextPage={nextPage} prevPage={prevPage} changePage={changePage} /> : null}
                    </DashBoardLayout>
                ) : (
                    <Loader />
                )}
            </Layout>
            <CreateCampaignModal
                open={open}
                register={register}
                isValid={isValid}
                handleSubmit={handleSubmit}
                onClose={onClose}
                errors={errors}
                name={{ first: "name", second: "code" }}
                label={{ first: "Campaign Name", second: "Campaign Code" }}
                reset={reset}
                onCampaignCreated={onCampaignCreated}
            />
            <BaseModal title="Edit Campaign" open={editCampaignOpenModal} onClick={editCampaignOnClose}>
                <StyledForm onSubmit={editHandleSubmit(onEditSubmit)}>
                    <FlexItem width="48%" mobileWidth="100%">
                        <Input
                            {...editRegister("name")}
                            label="Campaign Name"
                            inputType="text"
                            error={editFormState.errors}
                            backGroundColor="#12033d"
                            value={editInputValues.name}
                            modalInputActive
                        />
                    </FlexItem>
                    <FlexItem width="48%" mobileWidth="100%" mobileMargin="26px 0 0 0">
                        <Input
                            {...editRegister("code")}
                            label="Campaign Code"
                            inputType="text"
                            error={editFormState.errors}
                            backGroundColor="#12033d"
                            value={editInputValues.code}
                            disabled
                            modalInputActive
                            opacity={1}
                        />
                    </FlexItem>
                    <FlexItem width="100%" margin="24px 0 0 0" mobileMargin="42px 0 0 0">
                        <Button disabled={!editFormState.isValid} type="submit">
                            Save
                        </Button>
                    </FlexItem>
                </StyledForm>
            </BaseModal>
            <BaseModal title="Delete Campaign" open={deleteCampaignOpenModal} onClick={deleteCampaignOnClose}>
                <CampaignDeleteModalContent onCancel={deleteCampaignOnClose} onDelete={() => onDelete(id)} />
            </BaseModal>
        </>
    );
};

export default Campaigns;
